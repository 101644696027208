import { useState } from "react";
import "bulma/css/bulma.min.css";
import WheelComponent from "react-wheel-of-prizes";
import ImageTruth from "./asset/img/truth.png";
import ImageDare from "./asset/img/dare.png";
import ImageDareBackground from "./asset/img/dareBackground.png";
import imageTruthBackground from "./asset/img/truthBackground.png";
import imageLogo from "./asset/img/logo.png";

function App() {
  const [players, setPlayers] = useState([
    "Player 1",
    "Player 2",
    "Player 3",
    "Player 4",
    "Player 5",
  ]);
  const [segColors, setSegColors] = useState([
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
  ]);
  const [playerWin, setPlayerWin] = useState("");
  const [wheelKey, setWheelKey] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [DareClicked, setDareClicked] = useState(false);
  const [TruthClicked, setTruthClicked] = useState(false);
  const [dareResult, setDareResult] = useState("Loading...");
  const [truthResult, setTruthResult] = useState("Loading...");
  const onFinished = (winner) => {
    // console.log(winner);
    setPlayerWin(winner);
    setModalActive(true);
  };
  const closeModal = () => {
    setModalActive(false);
    setPlayerWin("");
    setDareClicked(false);
    setTruthClicked(false);
    setDareResult("Loading...");
    setTruthResult("Loading...");
  };

  const [newPlayer, setNewPlayer] = useState("");

  const handleAddPlayer = (e) => {
    e.preventDefault();
    if (newPlayer.trim() !== "") {
      setPlayers([...players, newPlayer]);
      setSegColors([...segColors, getRandomColor()]);
      setNewPlayer("");
      setWheelKey(Math.random().toString());
    }
  };

  const handleDeletePlayer = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers.splice(index, 1);
    setPlayers(updatedPlayers);
    setWheelKey(Math.random().toString());
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const handleDareClick = () => {
    // Handle Dare Click
    setDareClicked(true);

    // Call the API
    fetch("https://tebak-jodoh.aditiyak.site/dare?lang=id")
      .then((response) => response.json())
      .then((data) => {
        setDareResult(data.data);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleTruthClick = () => {
    setTruthClicked(true);

    // Call the API
    fetch("https://tebak-jodoh.aditiyak.site/truth")
      .then((response) => response.json())
      .then((data) => {
        setTruthResult(data.data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="App">
      <section className="section">
        <h1 className="title has-text-white is-family-monospace is-size-1">
          Truth Or Dare
        </h1>
        <center>
          <figure class="image is-128x128">
            <img src={imageLogo} />
          </figure>
        </center>
        <br />
        <div className="container">
          <div
            className={`columns ${
              window.innerWidth < 768 ? "is-flex-direction-column" : ""
            }`}
          >
            <div className="column is-half">
              <div className="card">
                <div className="card-content">
                  <h3 className="title">Player Lists</h3>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        className="input"
                        type="text"
                        placeholder="Masukkan nama pemain"
                        value={newPlayer}
                        onChange={(e) => setNewPlayer(e.target.value)}
                      />
                    </div>
                    <div className="control">
                      <button
                        className="button is-link"
                        onClick={handleAddPlayer}
                      >
                        Tambah Pemain
                      </button>
                    </div>
                  </div>
                  <div className="table-container">
                    {players.map((player, index) => (
                      <div key={index} className="p-2">
                        <div
                          className="card column"
                          style={{ backgroundColor: segColors[index] }}
                        >
                          <header className="card-header">
                            <p className="card-header-title has-text-white">
                              {player}
                            </p>
                            <button
                              className="button is-danger"
                              onClick={() => handleDeletePlayer(index)}
                              style={{ float: "right" }}
                            >
                              X
                            </button>
                          </header>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <footer className="card-footer"></footer>
              </div>
            </div>
            <div className="column is-half has-text-centered">
              <WheelComponent
                key={wheelKey}
                segments={players}
                segColors={segColors}
                onFinished={(winner) => onFinished(winner)}
                primaryColor="black"
                contrastColor="white"
                buttonText="Play"
                isOnlyOnce={false}
                size={220}
                upDuration={500}
                downDuration={600}
                fontFamily="Arial"
              />
            </div>
          </div>
        </div>
      </section>
      <div className={`modal ${modalActive ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div
          className="modal-content"
          style={{ overflow: "hidden", height: "100vh", width: "100vh" }}
        >
          {DareClicked ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="container-card">
                <img src={ImageDareBackground} className="image is-3by3" />
                <div
                  style={{
                    position: "absolute",
                    top: "47%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "20px",
                  }}
                >
                  {dareResult}
                </div>
              </div>
            </div>
          ) : TruthClicked ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="container-card">
                <img src={imageTruthBackground} className="image is-3by3" />
                <div
                  style={{
                    position: "absolute",
                    top: "47%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "20px",
                  }}
                >
                  {truthResult}
                </div>
              </div>
            </div>
          ) : (
            // This is the default state when neither DareClicked nor TruthClicked
            <div>
              <h1 className="is-size-2 is-size-5-mobile is-family-monospace">
                Pick Your Card
              </h1>
              <h2 className="is-size-3 is-size-6-mobile is-family-monospace">
                {playerWin}
              </h2>
              <div className="columns p-4">
                <div className="column">
                  <img
                    src={ImageTruth}
                    alt="Gambar A"
                    onClick={handleTruthClick}
                  />
                </div>
                <div className="column">
                  <img
                    src={ImageDare}
                    alt="Gambar B"
                    onClick={handleDareClick}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeModal}
        ></button>
      </div>
    </div>
  );
}

export default App;
